
import { Options, Vue } from 'vue-class-component';

import axios from 'axios';

import { HijackingResponse } from '@/interface/requests.interface';

import methods from '@/methods';
import requests from '@/requests';

@Options({})
export default class AuthHijacking extends Vue {
  created(): void {
    axios.get<HijackingResponse>(`${process.env.VUE_APP_BACKEND_URL}/profile/login/`, { withCredentials: true })
      .then((res) => {
        this.$store.commit('setupToken', { hijacking: true, token: res.data.token });

        requests.profile.getProfile()
          .then((res) => {
            methods.store.setupProfile(res.data);
            this.$router.push('/dashboard');
          })
          .catch(() => {
            // TODO: add error;
          });
      })
      .catch(() => {
        // TODO: add error;
      });
  }
}
